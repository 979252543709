<template>
  <v-container>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header-ex label="ペルソナ"></v-expansion-panel-header-ex>
        <v-expansion-panel-content>

          <persona-info></persona-info>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
import VExpansionPanelHeaderEx from '../../components/atomic/atoms/VExpansionPanelHeaderEx.vue';
import PersonaInfo from '../../components/atomic/organisms/PersonaInfo.vue';


export default {
  components: {
    VExpansionPanelHeaderEx,
    PersonaInfo
  },
}
</script>